import { Image, createStyles } from "@mantine/core";
import type {
    NextPage,
    GetServerSideProps,
    GetServerSidePropsContext,
} from "next";
import { merchants } from "api";
import useMerchantItems from "hooks/useMerchantItems";
import Head from "next/head";
import { useRouter } from "next/router";
import { ListItem } from "components/ListItem/ListItem";
import { useMediaQuery } from "@mantine/hooks";
import { Business } from "types/Merchant";
import { SelectLocationModal } from "components/Modal/SelectLocationModal";
import { useState } from "react";
import axios from "axios";
import { getMerchantLocationPath } from "helpers/url/urlHelpers";

export const getServerSideProps: GetServerSideProps = async ({
    params,
    req,
    res,
}: GetServerSidePropsContext) => {
    const merchantIdentifier = params?.merchant as string;
    try {
        const merchantInfo = await merchants.getBusinessDetails(
            merchantIdentifier
        );
        const merchant: Business = merchantInfo.data;
        if (merchant.locations.length === 1) {
            return {
                redirect: {
                    destination: `${getMerchantLocationPath(
                        merchant.subdomain,
                        merchant.locations[0].subdomain
                    )}`,
                    permanent: false,
                },
            };
        }

        return {
            props: {
                merchant,
            },
        };
    } catch (error) {
        console.log(error);
        if (axios.isAxiosError(error) && error.response?.status === 404) {
            return {
                notFound: true,
            };
        }
        throw error;
    }
};

interface MerchantStoreProps {
    merchant: Business;
}

const useStyles = createStyles((theme) => ({
    bannerImage: {
        paddingTop: 60,
    },
    main: {
        maxWidth: 1600,
        margin: "auto",

        [theme.fn.smallerThan("sm")]: {
            maxWidth: "auto",
        },
    },
    gridBox: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, 400px)",
        justifyContent: "center",
        justifyItems: "center",
        marginTop: 60,
        rowGap: 60,
        columnGap: 60,
        listStyle: "none",
        padding: "0px",

        [theme.fn.smallerThan("sm")]: {
            marginTop: 24,
            rowGap: 24,
            columnGap: 24,
            justifyItems: "start",
            gridTemplateColumns: "repeat(auto-fill, 350px)",
        },
    },
}));

const MerchantStore: NextPage<MerchantStoreProps> = ({
    merchant,
}: MerchantStoreProps) => {
    const [modalOpened, setModalOpened] = useState<string>(
        "selectLocationModal"
    );
    const router = useRouter();
    const { status, data, error, isFetching } = useMerchantItems(
        merchant.locations[0].subdomain
    );
    const { classes, theme } = useStyles();
    const smallScreen = useMediaQuery(
        `(max-width: ${theme.breakpoints.md})`,
        false
    );

    if (router.isFallback) {
        // TODO update this with fancy loading design
        return <></>;
    }

    return (
        <>
            <Head>
                <title>{merchant.name}&apos; Shop</title>
                <meta
                    property="og:title"
                    content={`${merchant.name}'s shop!`}
                />
                <meta
                    property="og:description"
                    content={`Shop new releases, merch, and events from ${merchant.name}`}
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content={`https://shop.oznr.com${getMerchantLocationPath(
                        router.query.merchant as string,
                        merchant.subdomain
                    )}`}
                />
                <meta property="og:image" content={merchant.darkLogoUrl} />
            </Head>
            <Image
                className={classes.bannerImage}
                src={merchant.bannerUrl}
                height={smallScreen ? 240 : 440}
                alt={`${merchant.name} banner image`}
            />
            <div className={classes.main}>
                <ul className={classes.gridBox}>
                    {data?.items?.map((item) => (
                        <li key={item.id}>
                            <div className="pointerCursor">
                                <ListItem item={item} />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <SelectLocationModal
                logoUrl={merchant.darkLogoUrl}
                opened={modalOpened === "selectLocationModal"}
                onClose={() => {}}
                withCloseButton={false}
                locations={merchant.locations}
                primaryColor={merchant.primaryColor}
            />
        </>
    );
};

export default MerchantStore;
